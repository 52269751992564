import React from 'react';
import PropTypes from 'prop-types';
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import {withRouter} from 'react-router-dom';

const Map = withScriptjs(withGoogleMap(({locations, center, history, zoom, startGps, startPin, startTitle}) => {
    const markers = locations.reduce((acc, loc) => {
        const {gps: {lat, lng} = {}, name, id} = loc;
        if(lat && lng && name) {
            acc.push({lat, lng, title: name, id})
        }
        return acc;
       }, []);
    const pins = markers.map(m => {
        const {lat, lng, title} = m;
        return (<Marker 
            key={title} 
            position={{lat, lng}} 
            title={title} 
            onClick={() => (markers.length > 1) ? handleOnClick(m) : null}/>);
    });
    if(startGps && startPin && startTitle) {
        const {lat, lng} = startGps;
        var image = {
            url: `${startPin}`
            // This marker is 20 pixels wide by 32 pixels high.
            // size: new google.maps.Size(20, 32),
            // // The origin for this image is (0, 0).
            // origin: new google.maps.Point(0, 0),
            // // The anchor for this image is the base of the flagpole at (0, 32).
            // anchor: new google.maps.Point(0, 32)
          };
        pins.push(<Marker 
            key={startTitle} 
            position={{lat, lng}} 
            title={startTitle} 
            icon={image} />) 
    }

    const handleOnClick = loc => {
        const {id} = loc;
        if(id) {
            history.push(`/location/${id}`);
        }
    };

    return (
            <GoogleMap
                defaultZoom={zoom}
                defaultMapTypeId="terrain"
                defaultOptions={{disableDefaultUI: false}}
                defaultCenter={center} >
                {<div>{pins}</div>}
            </GoogleMap>
    )
}))

Map.propTypes = {
    locations: PropTypes.arrayOf(PropTypes.object),
    center: PropTypes.object,
    zoom: PropTypes.number,
    startPin: PropTypes.string,
    startGps: PropTypes.object,
    startTitle: PropTypes.string
}

Map.defaultProps = {
    locations: [],
    center: {lat: 49.2339, lng: -124.8055},
    zoom: 10,
    startPin: null,
    startGps: null,
    startTitle: ''
}

export default withRouter(Map);
