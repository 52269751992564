import React from 'react';

import LocationThumbnail from './LocationThumbnail';

export const locs = [
    {
        id: 1,
        name: 'Burde Street Ponds',
        shortName: 'burde',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/burde-street-ponds-thumb.jpg',
        info: [
            {para:'The two Burde Street ponds — lower and upper (a.k.a. the Beaver Pond) — are in second-growth forest near the Arrowview Heights neighbourhood of Port Alberni. The ponds are largely on privately owned land, but they can be accessed from the Log Train Trail, a public right-of-way. North of the ponds is Crown Land, including Woodlot License W1902 operated by Hupacasath First Nation.'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/burde-street-ponds-02.jpg', className: 'full', alt: 'Burde Street Ponds', credit: 'Daryl Henderson'},
            {para: 'Winter and spring waterfowl include Ring-necked Duck in good numbers, Bufflehead, occasional Pied-billed Grebe; year-round — Hooded Merganser, Great Blue Heron; nesters — Canada Goose, Wood Duck, Mallard, Hooded Merganser.'},
            {para: 'Other species include Barred Owl; Belted Kingfisher; Red-breasted Sapsucker and other woodpeckers — Downy, Hairy, Pileated, Northern Flicker; flycatchers — Willow, Hammond’s, Pacific-slope; Chestnut-backed Chickadee; Red-breasted Nuthatch; Pacific Wren; kinglets — Golden-crowned and Ruby-crowned; Varied and Swainson’s Thrush; Dark-eyed Junco; Song Sparrow; Spotted Towhee; wood warblers, including Orange-crowned, MacGillivray’s, Yellow-rumped; Townsend’s (large numbers nest in trees along the Beaver Pond Trail and near the cutblocks at the top of this trail); Western Tanager.'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/burde-street-ponds-barred.jpg', className: 'full', alt: 'Burde Street Ponds', credit: 'Daryl Henderson'}, 
            {para: 'Beaver and river otter can be seen in both ponds, but best be there early in the morning to increase the odds.'},
            {hotspot: 'https://ebird.org/hotspot/L2245087'}, //  eBird hotspot is ‘Alberni Valley – Burde St Ponds.
            {para: 'Bears are common in spring, summer and fall.', className: 'caution'},
        ],
        gps: {lat: 49.243458, lng: -124.778004},
        directions: [
            {para: 'From the Visitor Info Centre (2533 Port Alberni Hwy, at the Y-junction with Hwy 4), turn R onto Port Alberni Hwy and continue to Redford St (~3.1 km); turn L at 17th Ave and continue for ~400 m; turn L at Burde St and continue for ~400 m; park on the wide shoulder just before Bracken Ln; the trail entrance (Log Train Trail) is marked by a yellow gate.'},
            {para: 'The lower pond is ~150 m down the trail, on the right. Approx. 420 m from the gate is the trail to the Beaver Pond — turn R at the sign post and follow this connector trail to the upper pond (~300 m); the connector trail is often very birdy.'}, 
            {para: 'Turning R at the top of the rise, now the Beaver Pond Trail, takes you past the south end of the pond and eventually back to Burde St.'}, 
            {para: 'For a longer walk through forest, turn L (north) and head up the Beaver Pond Trail for ~1.4 km, to where it joins the Log Train Trail. This 1.4-km segment takes you past several recent cutblocks, across a small creek (Wolf Creek; dry in summer), past the Bear Den Trail, and through a grove of live and dead deciduous trees, which is often a good place to spot woodpeckers. A Barred Owl pair has nested in this area for several years; they can be heard calling, if not seen.'}, 
            {para: 'At the junction marked with a signpost, turning R takes you down to Roger Creek (~200 m) and the <strong><a class="link" href="/location/6">Roger Creek Nature Trail</a></strong>. A trestle nearly 290 m in length once spanned this ravine and Roger Creek. Turning L at the junction takes you first to Wolf Creek (this section is steep on both sides of the creek) and then back to Burde St along the Log Train Trail, a flat and mostly straight route, part of an old railway grade (~1.2 km).'}
        ]
    },
    {
        id: 2,
        shortName: 'fossli',
        name: 'Fossli Provincial Park',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/fossli-provincial-park-thumb.jpg',
        info: [
            {para:'Off the beaten path, this little forest gem is well worth a visit for its beauty alone. Features include St. Andrew’s Creek, crossed by a small suspension bridge; waterfalls; marsh; a beach area overlooking Stirling Arm of Sproat Lake. Although this is a provincial park, it is undeveloped; however, the trails are marked and there is an outhouse near the beach.'},
            {para: 'Birds are most plentiful here in spring and summer. Species include Red-breasted Sapsucker; flycatchers — Olive-sided, Willow, Hammond’s, Pacific-slope; Chestnut-backed Chickadee; Brown Creeper; Pacific Wren; American Dipper; Golden-crowned Kinglet; Varied and Swainson’s Thrush; Western Tanager; warblers, include Yellow-rumped, Black-throated Gray, and Townsend’s.'},
            // {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/flicker.jpg', className: 'full', alt: 'Trail'}, 
            // {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/fossli-01.jpg', className: 'full', alt: 'Fossli Provincial Park'}, 
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/fossli-00.jpg', className: 'full', alt: 'Fossli Provincial Park', credit: 'Daryl Henderson'}, 
            {hotspot: 'https://ebird.org/hotspot/L4701408'}, // 'eBird hotspot is ‘Sproat Lake – Fossli Provincial Park.'
        ],
        gps: {lat: 49.2498831, lng: -124.9502053},
        directions: [
            {para: 'From the Visitor Info Centre (2533 Port Alberni Hwy, at the Y-junction with Hwy 4), take Hwy 4 through Port Alberni, heading west ~7.5 km. Turn L at the <strong>first</strong> McCoy Lake Rd (there are two McCoy Lake Rd access points off Hwy 4, and the second one is now a no-thru road). Travel ~6.5 km and then turn L on Stirling Arm Drive for about 3 km. Turn L onto Ash Main gravel road for 0.9 km. At the Stirling Arm Mainline intersection, turn R and travel ~3.9 km. Just before the bridge over St. Andrew’s Creek, you’ll find a pullout on the right side where you can park. An old logging road from the parking lot leads down to the trail that goes to Sproat Lake.'},
            {para: 'Watch out for and yield to logging trucks.', className: 'caution'}
        ]
    },
    {
        id: 3,
        shortName: 'harbour-quay',
        name: 'Harbour Quay',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/harbour-quay.jpg',
        info: [
            {para:'Located at the foot of Argyle Street in South Port, Harbour Quay is a focal point in the city, with numerous restaurants, shops and the Alberni Aquarium. Lady Rose Marine Services, which sails the MV Frances Barkley up and down the Alberni Inlet, is also based here.'},
            {para:'Birds in the Alberni Inlet are best viewed (with a spotting scope) from Centennial Pier, which juts into the inlet. The avifauna here include gulls (Glaucous-winged, Mew, California…), ducks and sea ducks such as Surf Scoter, loons (Common, Pacific, Red-throated), cormorants (Double-crested, Pelagic, occasional Brandt’s), and Bald Eagles.'},
            {para:'In breeding season (June/July), Purple Martins can be found nesting in boxes at Harbour Quay Marina (on Harbour Rd, approx. 200 m south of Argyle St).'},
            {hotspot: 'https://ebird.org/hotspot/L1012604'} // 'eBird hotspot is Harbour Quay.'
        ],
        gps: {lat: 49.234987, lng: -124.816116},
        directions: [
            {para: 'From the Visitor Info Centre in Port Alberni (2533 Port Alberni Hwy, at the Y-junction with Hwy 4), take Port Alberni Hwy for ~3.1 km, where it turns into Redford St. Follow Redford St for 1.4 km. Turn L on Third Ave (there is a traffic light at this intersection). Follow Third Ave for ~750 m, keeping to the right lane and then taking a slight R turn onto Kingsway Ave before the hill. After ~550 m, turn R onto Argyle St; straight ahead is the parking area for Harbour Quay.'}
        ]
    },
    {
        id: 4,
        shortName: 'kitsuksis-creek',
        name: 'Kitsuksis Creek and Dyke',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/kitsuksis-creek-and-dyke-thumb.jpg',
        info: [
            {para:'The flat, paved paths on both sides of Kitsuksis Creek and easy access make this ~2.5 km loop one of Port Alberni’s most popular places for walkers. Although in an urban setting, the dyke area has some good riparian habitat which attracts a variety of birds year-round, including waterfowl and songbirds.'},
            {para:'To add forest birds to your walk: At the north end of the loop, across Gertrude St, the paved paths continue on both sides of the creek through a wooded area before joining via a pedestrian bridge (this loop is ~0.6 km). Just beyond that bridge, on the north side of the creek, a dirt path (marked by a sign) continues creek-side through lush vegetation to the base of a rail trestle and a series of small waterfalls (~300 m).'},
            {hotspot: 'https://ebird.org/hotspot/L2055828'} // eBird hotspot is ‘Alberni Valley – Kitsuksis Dyke and Somass flats.’
        ],
        gps: {lat: 49.259811, lng: -124.814574},
        directions: [
            {para: 'From the Visitor Info Centre (2533 Port Alberni Hwy, at the Y-junction with Hwy 4), take Hwy 4/Johnston St (heading west) through Port Alberni for ~3.6 km to Margaret St. Turn R onto Margaret St and follow for ~130 m to Burke Rd, then turn L onto Burke Rd (note: Burke Rd has a boulevard separating the opposing lanes; turn L into the far lane). Straight ahead, near the Royal Canadian Legion, is a small parking lot with direct access to the walking paths.'}
        ]
    },
    {
        id: 5,
        shortName: 'mccoy-lake',
        name: 'McCoy Lake',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/mccoy-lake-thumb.jpg',
        color: '#9F9B63',
        info: [
            {para:'McCoy Lake attracts many species of waterfowl, including some uncommon birds such as Snow Goose and Ruddy Duck in fall and winter. Sandhill Cranes have also been seen in good numbers during spring and fall migration. The lake is on private farmland, so a spotting scope is required for best viewing from the crest of McCoy Lake Rd.'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/mccoy-lake-00.jpg', className: 'full', alt: 'McCoy Lake', credit: 'Daryl Henderson'}, 
            {hotspot: 'https://ebird.org/hotspot/L1855591'} //  eBird hotspot is ‘Port Alberni – McCoy Lake.’
        ],
        gps: {lat: 49.2682516, lng: -124.8872461},
        directions: [
            {para: 'From the Visitor Info Centre (2533 Port Alberni Hwy, at the Y-junction with Hwy 4), take Hwy 4 through Port Alberni, heading west for ~10.5 km. Turn L at the <strong>second</strong> McCoy Lake Rd access point (there are two McCoy Lake Rd access points off Hwy 4, the second one is signed ‘Road Closed’), and then follow the road past Littleton Dr to the top of the hill, near an old barn (several hundred metres). (As you head west on Hwy 4, the two roads immediately before McCoy Lake Rd are Stuart Ave and then Seaton Ave, both on the right side).'}
        ]
    },
    {
        id: 6,
        shortName: 'roger-creek',
        name: 'Roger Creek Nature Trail',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/fossli-provincial-park.jpg',
        color: '#9F9B63',
        info: [
            {para:'Roger Creek flows east to west through Port Alberni, dividing the city in two. There are multiple access points and trails to the creek along its meandering course. Perhaps the most scenic is the Roger Creek Nature Trail, in the northeast part of the city.'},
            {para:'The avifauna consists mainly of forest birds such as Barred Owl, various woodpeckers (Red-breasted Sapsucker, Downy, Hairy, Pileated, Northern Flicker), Chestnut-backed Chickadee, Pacific Wren and Spotted Towhee, but also American Dipper and Common Merganser have been seen on the creek. This location is not listed as an eBird hotspot.'},
            {para: 'Bears are common in spring, summer and fall.', className: "caution"},
        ],
        gps: {lat: 49.257575, lng: -124.774920},
        directions: [
            {para: 'From the Visitor Info Centre (2533 Port Alberni Hwy, at the Y-junction with Hwy 4), turn R onto Port Alberni Hwy and travel ~1.5 km. After crossing the new Roger Creek road bridge, the small unpaved parking lot at the trailhead is on the left side of the highway, just past Victoria Drive N (which is on the right side of the highway). The Roger Creek Nature Trail has many twists and turns and ups and downs. About 800 m in, the trail intersects the Log Train Tail (see <strong><a class="link" href="/location/1">Burde Street Ponds</a></strong>). Here, a railway trestle nearly 290 m in length once spanned this ravine and Roger Creek.'}
        ]
    },
    {
        id: 7,
        shortName: 'somass-estuary',
        name: 'Somass River Estuary',
        footerCredit: 'Penny Hall',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/turkey-vulture.jpg',
        footerImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/footer-somas.png',
        color: '#9F9B63',
        info: [
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/estuary-00.jpg', className: 'left', alt: 'Somass Estuary', credit: 'Penny Hall'},
            {para:'The Somass Estuary, where the Somass River empties into tidal Alberni Inlet, is the Alberni Valley’s premier birding hotspot — nearly 180 species have been reported on eBird.'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/estuary-01.jpg', className: 'left', alt: 'Somass Estuary', credit: 'Penny Hall'},
            {para:'The estuary’s diverse habitat offers mudflats, salt marshes, meadows, ponds, upland areas of deciduous and coniferous forest, a poplar plantation, a cultivated field, sewage lagoons. Much of the area is held and protected by Ducks Unlimited; other landholders are Catalyst Paper Corporation, City of Port Alberni, Island Timberlands (Mosaic), and Tseshaht First Nation.'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/estuary-02.jpg', className: 'left', alt: 'Somass Estuary', credit: 'Penny Hall'},
            {para:'Ducks, geese and other waterfowl are abundant, especially in late fall and winter. Often present are Cackling Goose, Canada Goose, Trumpeter Swan, Northern Shoveler, Gadwall, American Wigeon, Mallard, Northern Pintail, Green-winged Teal, Ring-necked Duck, Greater and Lesser Scaup, Bufflehead, Common Goldeneye, Hooded and Common Merganser, Pied-billed Grebe, Virginia Rail, American Coot, among others.'},
            {para:'Gulls include Mew Gull, California Gull and Glaucous-winged Gull...'},
            {para:'Shorebird visitors include Killdeer, Least Sandpiper, Western Sandpiper, dowitchers, Spotted Sandpiper (nests here), Greater and Lesser Yellowlegs…'},
            {para:'Year-round species include Great Blue Heron, Bald Eagle and Red-tailed Hawk (all nest here).'},
            {para:'Other raptor visitors are Northern Harrier, Sharp-shinned and Cooper’s Hawks, Great Horned Owl, Northern Pygmy-owl, Barred Owl, Short-eared Owl…'},
            {para:'Other notables: Tree and Violet-green Swallows (both nest here in boxes), Barn Swallow, sparrows (Fox, Golden-crowned, Song), woodpeckers (Red-breasted Sapsucker, Downy, Hairy, Pileated, Northern Flicker), wrens (Bewick’s, Marsh, Pacific), Red-winged Blackbird…'},
            {para: 'Be cautious of bears in spring, summer and fall, especially when mothers are with cubs.', className: 'caution', credit: 'Penny Hall'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/estuary-03.jpg', className: 'full', alt: 'Somass Estuary', credit: 'Penny Hall'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/estuary-04.jpg', className: 'full', alt: 'Somass Estuary', credit: 'Penny Hall'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/estuary-05.jpg', className: 'full', alt: 'Somass Estuary', credit: 'Penny Hall'},
        ],
        gps: {lat: 49.252104, lng: -124.830358},
        directions: [
            {para: 'From the Visitor Info Centre (2533 Port Alberni Hwy, at the Y-junction with Hwy 4), take Hwy 4 west through Port Alberni for ~7.8 km. After crossing the historic ‘Orange Bridge’ (it’s no longer orange), turn L at the first road, Tsuma-as Dr (just past the Tseshaht First Nation building); there is a left-turn lane. Continue along Tsuma-as Dr, past Gallic Rd and Mission Rd, for ~0.6 km to a fork in the road. At this point you have two options for parking and accessing the estuary:'},
            {para: '(1) Taking the L fork puts you on a gravel road (the old Airport Road) that runs along the Somass River; park ~200 m down this road by the gate. Continue by foot on this road for ~1.3 km until you reach a large field (owned by Ducks Unlimited). This area is good for all manner of species, including geese and other waterfowl, raptors, swallows (note the nesting boxes), Western Meadowlarks and more. At the NW corner of the field is a trail which runs along a berm to a gravel road beside the elevated water pipeline. This bushy trail is good for sparrows, finches and surprises, and has good vantage spots overlooking a small tidal marsh.'},
            {hotspot: 'https://ebird.org/hotspot/L2600157'}, // eBird hotspot is ‘Alberni Valley – Somass Estuary Gate.’
            {para: '(2) Taking the R fork keeps you on the paved road, and Tsumas-as Dr becomes Shoemaker Bay Rd. Follow Shoemaker Bay Rd for ~1 km until you reach a yellow gate. Park just before the gate, either in the pullout on the right or on the other side of the road facing the way you came in. (Be advised that the gate can be locked at any time, so it’s best to park as indicated above and not get trapped behind the gate!) At this point, a large pipe emerges from the ground and runs eastward across the estuary. The pipe carries water from Stirling Arm of Sproat Lake to the Catalyst Paper Mill. We do not condone walking along the elevated pipeline due to potential hazards. Instead, walk through the gate for ~150 m and follow the gravel access road on the left into the estuary; this road meets up with the pipeline after ~850 m. At the pipeline, turn R and continue along the gravel road for ~430 m to reach the first sewage lagoon.'},
            {hotspot: 'https://ebird.org/hotspot/L210234'}, //  eBird hotspot is ‘Port Alberni – Somass Estuary and sewage lagoons.’
            {para: 'Unfortunately (for birds and birders), the sewage lagoons are being upgraded, so access to them is currently restricted. The project, including habitat restoration, is expected to be completed by June 2020.'}
        ]
    },
    {
        id: 8,
        shortName: 'sproat-landing',
        name: 'Sproat Lake Landing',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/sproat-lake-landing-thumb.jpg',
        info: [
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/sproat-lake-landing-00.jpg', className: 'full', alt: 'Sproat Lake Landing', credit: 'Daryl Henderson'},
            {para:'Located on Lakeshore Rd adjacent to Hwy 4 about 15 km west of Port Alberni, this scenic little estuary teems with birdlife and is a recently added eBird hotspot, ‘Sproat Lake Landing.’ And don’t miss the fine beaver lodge!'},
            {para:'Relax and enjoy splendid views of the West Bay section of Klehkoot Arm and Mount Arrowsmith in the distance. Drinkwaters Restaurant (seasonal) and Dellas Café (open year-round) offer good food and beverages.'},
            {para:'On the other side of Hwy 4 there is an adjoining wetland, which can be viewed from spots along Kimola Rd.'},
            {hotspot: 'https://ebird.org/hotspot/L8599926'} // ‘Sproat Lake Landing.’
        ],
        color: '#899560',
        gps: {lat: 49.285066, lng: -124.974562},
        directions: [
            {para: 'From the Visitor Info Centre in Port Alberni, take Hwy 4 west for ~18 km (you will pass Sproat Lake Provincial Park on your way). Turn L onto Lakeshore Rd (there is a left-turn lane). Sproat Lake Landing is straight ahead.'}
        ]
    },
    {
        id: 9,
        shortName: 'sproat-lake-prov-park',
        name: 'Sproat Lake Provincial Park',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/sproat-lake-provincial-park-thumb.jpg',
        info: [
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/sproat-lake-provincial-park-01.jpg', className: 'full', alt: 'Sproat Lake Provincial Park', credit: 'Daryl Henderson'}, 
            {para:'Sproat Lake Provincial Park is a good place to find both forest birds and waterbirds, the latter especially in fall and winter. The park bustles with campers and boaters in July and August, so nearby <a class="link" href="/location/2">Fossli Provincial Park</a> is an excellent alternative for birding the area in summer. While at Sproat Lake Provincial Park, be sure to visit the petroglyphs on your walk.'},
            {hotspot: 'https://ebird.org/hotspot/L354488'} //  eBird hotspot is ‘Sproat Lake Provincial Park.’

        ],
        color: '#423D4B',
        gps: {lat: 49.291773, lng: -124.928012},
        directions: [
            {para: 'From the Visitor Info Centre in Port Alberni, take Hwy 4 west (through Port Alberni) for ~14 km. The park’s main entrance is well signed. There are two options for parking: the boat launch parking lot or the day-use parking lot farther in; both have beach access.'}
        ]
    },
    {
        id: 10,
        shortName: 'sproat-lake-taylor-arm',
        name: 'Sproat Lake – Taylor Arm Delta',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/taylor-Arm-delta-thumb.jpg',
        info: [
            {para:'Fjord-like Taylor Arm is the most westerly part of Sproat Lake. The Taylor River flows into the western end of Taylor Arm, creating a small delta. It’s a rustic setting of second-growth forest, more-recently logged areas, riverine gravel bars and riparian habitat.'},
            {hotspot: 'https://ebird.org/hotspot/L4705296'}, //  eBird hotspot is ‘Sproat Lake – Taylor River delta.’
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/taylor-arm.jpg', className: 'full', alt: 'Taylor Arm', credit: 'Daryl Henderson'},
        ],
        gps: {lat: 49.288396, lng: -125.212572},
        color: '#CD201F',
        directions: [
            {para: 'From the Visitor Info Centre in Port Alberni, take Hwy 4 west for ~37 km. At several points along Hwy 4 you will see Taylor Arm on your left, and you will also pass Taylor Arm Provincial Park. Past the end of the lake, the highway descends slightly into an open area. A large yellow sign asking <strong>“Was your campfire completely out? Report wildfires”</strong> is located immediately before your turnoff, which is on the left; however, if traffic is heavy, turn R into the pullout area instead and then cross the highway when all is clear. Turn L onto the gravel road and follow it for ~0.9 km to where it becomes impassable by vehicles (because of a large ditch). The road is rough (with potholes) but level and okay for most 2WD vehicles. On the other side of the ditch, the gravel road continues to Taylor Arm, where there is a rough campsite and boat launch used mainly by fishermen and other locals (vehicle access to the campsite is via another entrance farther east on Hwy 4, but a truck or high-clearance SUV is required). Birding can be good anywhere along the gravel road and lakeside, depending on the season. A spotting scope is recommended for viewing birds on Taylor Arm itself.'}
        ]
    },
    {
        id: 11,
        shortName: 'stamp-river',
        name: 'Stamp River Provincial Park',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/stamp-river-thumb.jpg',
        info: [
            {para:'Not extremely birdy, but otherwise sublime: old-growth forest, waterfalls and rapids, salmon leaping and Black Bears! And if you’re after amazing American Dippers, Stamp River is Dipper Central.'},
            {para:'Historically, salmon return to the Stamp River in late summer through December, when they can be seen jumping in the falls and swimming in the fish ladders: Coho (mid-August to December); Sockeye (late June to early September); Chinook (mid-September to mid-November). Adding to the spectacle is the large number of bears attracted to the homing fish.'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/stamp-river-00.jpg', className: 'left', alt: 'Stamp River', credit: 'Nicole Beaulac'}, 
            {para:'Speaking of migrations, the Alberni Valley is on the migration route of the the coastal population of Sandhill Cranes, which winters mainly in the Central Valley of California. If not high overhead, cranes can sometimes be seen foraging/resting in farm fields along Beaver Creek Rd, both before and past the park, during spring (April) and fall (September) migrations.'},
            {hotspot: 'https://ebird.org/hotspot/L2187058'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/stamp-river-06.jpg', className: 'full', alt: 'Stamp River', credit: 'Penny Hall'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/stamp-river-05.jpg', className: 'full', alt: 'Stamp River', credit: 'Penny Hall'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/stamp-river-07.jpg', className: 'full', alt: 'Stamp River', credit: 'Penny Hall'},
        ],
        color: '#9F9B63',
        gps: {lat: 49.3337, lng: -124.9182},
        directions: [
            {para: 'From the Visitor Info Centre in Port Alberni, take Hwy 4 west through Port Alberni until the road comes to a T intersection with traffic lights. Shortly after turning R at the T junction, turn R onto Beaver Creek Rd, just before the Petro-Canada station (~4.5 km from the Visitors Centre). Follow Beaver Creek Rd for ~12.5 km. Turn L into the park entrance and follow the road down to the river, ~1.7 km (the park road is unpaved and rough at times).'}
        ]
    },
    {
        id: 12,
        shortName: 'victoria-quay',
        name: 'Victoria Quay',
        backgroundImage: 'https://s3-us-west-2.amazonaws.com/bird-alberni/victoria-quay-thumb.jpg',
        info: [
            {para:'This easily accessible spot, at the western terminus of Johnston Rd, overlooks the Somass River and Estuary. Roger Creek empties here, as well.'},
            {para:'The Victoria Quay area hosts a variety of waterfowl (ducks and geese), gulls and passerines, although typically not in large numbers. Bald Eagle and Great Blue Heron can usually be spotted here.'},
            {image: 'https://s3-us-west-2.amazonaws.com/bird-alberni/victoria-quay-00.jpg', className: 'full', alt: 'Victoria Quay', credit: 'Daryl Henderson'}, 
            {hotspot:  'https://ebird.org/hotspot/L4999650'}
        ],
        color: '#899560',
        gps: {lat: 49.256960, lng: -124.813504},
        directions: [
            {para: 'From the Visitor Info Centre in Port Alberni, take Hwy 4 (Johnston Rd) west through Port Alberni until the road comes to a T intersection with traffic lights (~4.4 km from the Visitors Centre). Turn L, and then R into the second parking lot along the Somass River.'}
        ]
    }
];

class Locations extends React.Component {

    renderLocations = () => {
        return locs.map(l => (<li key={l.name}><LocationThumbnail location={l} /></li>));
    }

    render() {
        return(
            <div className="locations">
                <ul>{this.renderLocations()}</ul>
          </div>
        )
    }
}

export default Locations;
