import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom'

class LocationThumbnail extends React.Component {

    handleOnClick = () => {
        console.log(this.props.location.name || '')
    }

    render() {
        const {location} = this.props;
        const {backgroundImage, color, name, id} = location;
        const linkStyle = {textDecoration: 'none'};
        const ContentDiv = styled.div`
            background-image: url('${backgroundImage}');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            background-color: ${color};
            transition: all 0.5s;
            `;

       return(
            <Link 
                style={linkStyle}
                key={name} 
                to={{
                    pathname: `/location/${id}`
                }}
                params={{location}}>
                <ContentDiv className="location">
                    {name || ''}
                </ContentDiv>
            </Link>
        )
    }
}

LocationThumbnail.propTypes = {
    location: PropTypes.object
}

LocationThumbnail.defaultProps = {
    location: {}
}


export default LocationThumbnail;
