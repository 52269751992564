import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import {isMobile} from 'react-device-detect';

import MainInfo from './MainInfo';
import Map from './Map';
import Directions from './Directions';

class Location extends React.Component {

    render() {
        const {loc = {}} = this.props;
        const {name, info = [], gps, directions, startGps, startPin, startTitle, footerImage, footerCredit} = loc;
        const locationClass = classNames(
            'location-container', 
            {
              'mobile': isMobile
            }
          );
        const ContentDiv = footerImage 
        ? styled.div`
            background-image: url("${footerImage}");
        ` 
        : styled.div``;
        const footerClass = classNames({
            'footer footer-location': footerImage,
            'footer-loc': !footerImage
        });     

        return(
            <div className={locationClass}>
                <div className="jumbotron">
                    <div className="location-title">
                        {name}
                    </div>
                </div>
                <MainInfo info={info} />
                <Map
                    locations={[loc]}
                    startGps={startGps}
                    startPin={startPin}
                    startTitle={startTitle}
                    center={gps}
                    zoom={13}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA5ZDfMq0oGXJXVX6OKI4_8zijkaH_ZsFc&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
                {directions && <Directions directions={directions} />}
                <footer>
                    <ContentDiv className={footerClass}>
                        <p>{`© ${footerCredit || 'Bird Alberni 2022'}`}</p>
                    </ContentDiv>
                </footer>
           </div>
        )
    }
}

Location.propTypes = {
    loc: PropTypes.object
}

Location.defaultProps = {
    loc: {}
}


export default Location;
