import React from 'react';
import PropTypes from 'prop-types';

class MainInfo extends React.Component {

    render() {
        const {title, info} = this.props;
        const markup = info.reduce((acc, i, j) => {
            const {para, hotspot, image, className, alt, credit} = i;
            if(para) {
                return acc + `<p class=${className || ''}>${para}</p>`;
            }
            if(hotspot) {
                return acc + `<p class="hotspot"><a href="${hotspot}" target="_blank"><span class="eBird-e">e</span><span class="eBird-bird">Bird</span></a></p>`;
            }
            if(image) {
                const imgComp = `<img src="${image}" alt={${alt}} />`;
                const creditComp = credit ? `<p class="credit">© ${credit}</p>` : '';
                return acc + `<div class="image-container ${className}">${imgComp + creditComp}</div>`;
            }
            return acc;
        }, '');

       return(
            <div className="main-info">
                {title}
                <div dangerouslySetInnerHTML={{__html:markup}} />
            </div>
        )
    }
}

MainInfo.propTypes = {
    title: PropTypes.string,
    info: PropTypes.arrayOf(PropTypes.object)
}

MainInfo.defaultProps = {
    title: '',
    info: []
}


export default MainInfo;
