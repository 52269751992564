import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {isMobile} from 'react-device-detect';

import MainInfo from './MainInfo';

class Info extends React.Component {

    render() {
        const {title, info} = this.props;

        const aboutClass = classNames(
            'about', 
            {
              'mobile': isMobile
            }
          );
        
        return(
           <div className={aboutClass}>
                <div className="content">
                    <div className="jumbotron">
                        <div className="title info">
                            {title}
                        </div>
                    </div>
                    <div className="site-info">
                        <MainInfo info={info}/>
                    </div>
                </div>
                <footer>
                    <div className="footer-loc">
                    <p>© Bird Alberni 2022</p>
                    </div>
                </footer>
          </div>
        )
    }
}

Info.propTypes = {
    title: PropTypes.string,
    info: PropTypes.arrayOf(PropTypes.object)
}

Info.defaultProps = {
    title: '',
    info: []
}

export default Info;
