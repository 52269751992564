import React from 'react';
import PropTypes from 'prop-types';

class Directions extends React.Component {

    render() {
        const {directions} = this.props;
        const markup = directions.reduce((acc, dir) => {
            const {className, para, hotspot} = dir;
            if(para) {
                acc += `<p class="${className}">${para}</p>`;
            }
            if(hotspot) {
                return acc + `<p class="hotspot"><a href="${hotspot}" target="_blank"><span class="eBird-e">e</span><span class="eBird-bird">Bird</span></a></p>`;
            }

            return acc;
        }, '');

       return(
            <div className="directions">
                <div className="title">DIRECTIONS</div>
                <div dangerouslySetInnerHTML={{__html:markup}} />
            </div>
        )
    }
}

Directions.propTypes = {
    directions: PropTypes.arrayOf(PropTypes.object)
}

Directions.defaultProps = {
    directions: []
}

export default Directions;
