import React from 'react';
import classNames from 'classnames'
import {isMobile} from 'react-device-detect';

import Locations, {locs} from './Locations';
import MainInfo from './MainInfo';
import Map from './Map';

const info = [
    {para: 'A birderʼs guide to beautiful Alberni Valley, Vancouver Island, British Columbia, Canada'}
];

class Home extends React.Component {

    render() {
        const homeClass = classNames(
            'home', 
            {
              'mobile': isMobile
            }
          );
        
        return(
           <div className={homeClass}>
                <div className="content">
                    <div className="jumbotron">
                        <div className="title">
                            <span className="bird">bird </span>
                            <span className="alberni">alberni </span>
                        </div>
                    </div>
                    <div className="site-info">
                        <MainInfo info={info}/>
                        <Locations />
                    </div>
                </div>
                <Map
                    locations={locs}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA5ZDfMq0oGXJXVX6OKI4_8zijkaH_ZsFc&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
                <footer>
                    <div className="footer">
                    <p>© Penny Hall</p>
                    </div>
                </footer>
           </div>
        )
    }
}

export default Home;
