import React, {Component} from 'react';
import {Route, BrowserRouter as HashRouter, Switch, Link} from 'react-router-dom'
import ReactGA from 'react-ga';
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';

import Info from './Info';
import Home from './Home';
import Location from './Location';
import ScrollToTop from './ScrollToTop';
import {locs} from './Locations';

import './App.css';
import './bird.css';

const aboutInfo = [
  {para: 'Bird Alberni is an initiative of members of Port Alberni‘s birding (‘birdwatching’) community, an informal but passionate group of aviphiles.'},
  {para: 'The goals of Bird Alberni are <ol class="reasons"><li>to highlight year-round opportunities for birding in the Alberni Valley and promote the region as a birding destination for visitors and locals alike;</li><li>to foster an appreciation of the wonderful variety of birdlife and bird habitat of the Alberni Valley, with a view toward protection and conservation; and</li><li>to answer queries about birds and birding in the Alberni Valley (through our <a class="link" href="/contact">Contact page</a>).</li></ol>'},
  {para: 'We are grateful to Penny Hall and Nicole Beaulac for sharing their excellent photographs. The images are copyrighted.'}
];

const contactInfo = [
  {para: 'For information about birds or birding in the Alberni Valley or about this website, contact <a class="mail" href="mailto:info@birdalberni.ca?Subject=Info Request">info@birdalberni.ca</a>. Please allow up to 24 hours for a response.'}
];

const xmasInfo = [
  {para: 'Between December 14, 2022 and January 5, 2023, tens of thousands of birders and winter enthusiasts will rally together to count millions of birds across North America as part of the 122nd Christmas Bird Count. The count in Port Alberni will take place on Saturday December 31, 2022 — sun, rain or snow.'},
  {para: 'Each year, Birds Canada and the National Audubon Society help coordinate and support the efforts of more than 2500 counts throughout the Western Hemisphere. Data collected during the Port Alberni Christmas Bird Count include details on the number of birds of each species seen or heard within a local 24-km diameter circle, centred at Victoria Quay. Surveying this circle year-after-year contributes valuable long-term information on how our winter birds are faring.'},
  {para: 'Novice or experienced, the Christmas Bird Count is for everyone. Whether you like exploring forests, fields, and waters in search of lingering migrants, or prefer counting feeder birds from your window with a warm mug in hand, the Christmas Bird Count offers diverse opportunities for participation. No matter how you contribute, all Christmas Bird Count observations are used to help study the health of winter bird populations over time and guide conservation strategies to support birds and their habitats.'},
  {para: '“Every Christmas Bird Count participant is an important part of this valuable project for birds,” say Liz Purves, Birds Canada’s Christmas Bird Count Coordinator. “Whether you participate for bird conservation, for some friendly birding competition, or for an excuse to get outside in winter, your efforts are meaningful for birds.” The skills and dedication of thousands of volunteer Citizen Scientists harnessed during the Christmas Bird Count achieve incredible results that professional ornithologists and wildlife biologists could never accomplish alone. During last year’s count in Port Alberni, slightly more than ten thousand birds of over 80 species were recorded.'},
  {para: 'The Christmas Bird Count took root over a century ago when 27 birders in 25 localities from Toronto, Ontario to Pacific Grove, California, led by ornithologist Frank Chapman, proposed a conservation-minded alternative to the traditional ‘side-hunt,’ a Christmas Day competition to kill the most birds and small mammals. This alternative initiative to identify, count, and record all birds on Christmas Day 1900 has become North America’s longest-running Citizen Science project. Port Alberni’s Christmas Bird Count has been held for nearly 30 years.'},
  {para: 'For queries about Port Alberni’s Christmas Bird Count, please send an email to <a class="mail" href="mailto:info@birdalberni.ca?Subject=Port Alberni’s Christmas Bird Count">info@birdalberni.ca</a>. For more information about the Christmas Bird Count in general, visit the <a class="link" href="https://www.birdscanada.org/bird-science/christmas-bird-count/" target=blank">Birds Canada website</a>'}
];

class App extends Component {
  constructor() {
    super();
    ReactGA.initialize('UA-138984411-1');
  }

  render() {
    const headerClass = classNames(
      'header', 
      {
        'mobile': isMobile
      }
    );
    
    // const isRoot = this.props.location.pathname === '/';
    // const footerClass = classNames(
    //   {
    //     'footer': isRoot,
    //     'footer-loc': !isRoot
    //   }
    // );


    return (
      <HashRouter>
      <ScrollToTop>
      <div className="App">
        <header>
          <div className={headerClass}>
              <div className="title">
                <Link to="/" >
                  <span className="bird">bird </span>
                  <span className="alberni">alberni </span>
                </Link>
              </div>
            <div className="nav">
                <ul>
                  <Link to="/about" >
                    <li>About</li>
                  </Link>
                  <Link to="/contact" >
                    <li>Contact</li>
                  </Link>
                </ul>
                <Link to="/christmas" >
                    <img src="https://s3-us-west-2.amazonaws.com/bird-alberni/christmas.png" alt="Christmas Bird Count" />
                  </Link>
              </div>
          </div>
      </header>
      <main>
        <Switch>
          <Route exact path="/" render={() => {
            ReactGA.pageview('/home');
            return (<Home />);
          }}/>
          <Route path="/location/:id"  render={p => {
            const {match: {params: {id} = {}} = {}} = p;
            if(id) {
              const idAsInt = parseInt(id, 10, 0);
              if(idAsInt) {
                const location = locs.find(loc => loc.id === idAsInt)
                if(location) {
                  ReactGA.pageview(`/location/${location.shortName}`);
                  return (<Location loc={location} />);
                }
                }
            }

            return (<Home />);
          }}/>

          <Route exact path="/about" render={() => {
            ReactGA.pageview('/about');
            return (<Info title="About" info={aboutInfo} />);
          }}/>
          <Route exact path="/contact" render={() => {
            ReactGA.pageview('/contact');
            return (<Info title="Contact" info={contactInfo} />);
          }}/>
          <Route exact path="/christmas" render={() => {
            ReactGA.pageview('/christmas');
            return (<Info title="Christmas Bird Count 2022" info={xmasInfo} />);
          }}/>
        </Switch>
      </main>
      </div>
      </ScrollToTop>
      </HashRouter>

    );
  }
}

export default App;
